@import '../assets/stylesheets/style.scss';

.content {
	h3 {
		font-size: 2rem;
		margin-bottom: 0.5rem;
	}

	p {
		margin: 1rem 0;
	}

	ul {
		padding-left: 4rem;
		font-size: 1.8rem;
		margin: 1rem 0;
	}
}
