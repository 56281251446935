@import '../assets/stylesheets/style.scss';

.mission {
	.board-header {
		margin: 4rem 2rem 2rem;

		h2 {
			font-size: 3.5rem;
			color: $color-primary;
			text-transform: uppercase;
		}
	
		hr {
			background-color: $color-primary;
		}
	}

	.board-member {
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;
		padding-top: 0;

		.member-info {
			flex: 1 1;
		}

		h3 {
			font-size: 3.5rem;
			color: $color-accent;
		}

		h4 {
			font-size: 2rem;
		}

		p {
			margin-top: 3rem;
			font-size: 2rem;
		}

		> div {
			padding: 0 2rem;
		}

		.side img {
			width: 22rem;
			margin-top: 1rem;
		}
	}
}
