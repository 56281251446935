@import '../../assets/stylesheets/style.scss';

.donation-examples {
	background-color: $color-secondary;
	display: flex;
  justify-content: center;

  @media (max-width: 1060px) {
    display: none;
  }
  
  hr {
    display: none;
  }
  
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: solid 0.3rem #e6ffae;
    padding: 0 2rem;
    margin: 2rem 0;

    &:last-child {
      border-right: none;
      background: url('../../assets/images/dog-paw.png') right no-repeat;
      margin: 0;
    }
  }

  .money {
    color: #fff;
    font-size: 6rem;
    font-weight: 900;
    margin-right: 1rem;
  }

  .caption {
    color: #e6ffae;
    font-size: 2.5rem;
    font-weight: 900;
    line-height: 2.5rem;
  }
}
