@import '../../assets/stylesheets/style.scss';

.home-links {
	background-image: url('../../assets/images/about_bg.png');
	display: flex;
  justify-content: center;

  @media (max-width: 870px) {
    display: none;
  }
  
  h1 {
    font-size: 3.5rem;
    color: $color-secondary;
  }

  > div {
    padding: 2rem 8rem 3.8rem;
  }

  p {
    margin-bottom: 2.3rem;
  }
}
