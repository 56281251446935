@import '../../assets/stylesheets/style.scss';

.home-news-events {
	background-image: url('../../assets/images/largeBlueCat.png');
  padding: 2rem 6rem 2.8rem;
  
  @media (max-width: 870px) {
    display: none;
  }
  
  h1, hr, .panel {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  h1 {
    font-size: 3.5rem;
    color: #fff;
  }

  h2 {
    color: #000;
    font-size: 3rem;
  }

  h3 {
    color: $color-accent;
    font-size: 2.4rem;
  }

  hr {
    background-color: $color-accent;
  }

  .kenLogo {
    width: 100%;
  }

  .panel-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #444;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 1.3;

    hr {
      width: 100%;
      margin: 0;
    }

    img {
      max-width: 80%;
      max-height: 14.4rem;
      margin: 1rem auto;
    }

    > .btn {
      position: absolute;
      bottom: 2rem;
      margin-top: 1rem;
    }
  }

  .stat {
    font-size: 2.4rem;
    text-align: center;

    span {
      color: $color-accent;
    }
  }

  .center {
    text-align: center;
  }

  .panels {
    display: flex;
  }

  .panel {
    position: relative;
    margin-bottom: 2rem;
    background-color: #fff;
    border-radius: 1.5rem;
    padding: 2rem 4rem 7rem;
    flex: 1;
  }

  .date {
    background-image: url('../../assets/images/news_header.png');
    float: right;
    height: 10.3rem;
    width: 12.7rem;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      font-family: 'Arimo', 'Roboto', 'CartoGothic Std', 'Lucida Grande', 'Lucida Sans Unicode', Verdana, sans-serif;
      font-size: 5rem;
      font-weight: bolder;
      color: #fff;
      margin-left: 2.5rem;
    }
  }

  .friend-logo {
    min-height: 14.4rem;
    margin: 1rem;
    display: flex;
    align-items: center;
  }
}
