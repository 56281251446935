@import '../assets/stylesheets/style.scss';

.animal-card {
	margin: 4rem 0;
	width: 23rem;
	border: 1px solid #b1b1b1;
	border-radius: 0.5rem;
	padding: 1.5rem 1rem 1rem;

	a {
		align-self: center;
	}

	img {
		max-height: 23rem;
		max-width: 100%;
	}

	.pet-card {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 100%;
	}

  h3 {
    color: $color-secondary;
    font-size: 3.0rem;
	}
	
	ul {
		list-style: none;
		font-weight: 500;
	}

	label {
		display: none;
	}

	.description {
		display: flex;
		flex-direction: column;

		.details-link {
			text-decoration: none;
			align-self: flex-end;
			margin-top: 1.4rem;
		}
	}
}
