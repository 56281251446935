@import './_variables.scss';
@import './_mixins.scss';
@import './_grid.scss';

/*
Colors :



*/

/* CSS Reset */
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
}

body {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  /*font-size: 16px;*/
  line-height: 1.7;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

img {
  display:block;
}

a {
  color: $color-accent;
  font-weight: 700;
}

.btn {
  display: inline-block;
  font-family: 'Raleway', sans-serif;
  font-size: 1.4rem;
  font-weight: bolder;
  background-color: $color-accent;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  padding: 1.2rem 3rem;
  border-radius: 1.7rem;
  line-height: normal;
  text-align: center;

  &:hover {
    transform: translateY(-0.3rem);
  }

  &:active {
    transform: translateY(-0.1rem);
  }

  &--secondary {
    background-color: $color-secondary;
    color: #fff;
  }
}

button {
  cursor: pointer;
  
  &.btn {
    border: none;
  }
}

hr {
  background-color: #fff;
  margin: .5rem 0 2rem;
  width: 4rem;
  height: 0.3rem;
  border: 0;
}

// .popup-content {
//   margin: auto;
//   background: rgb(255, 255, 255);
//   width: 50%;
//   padding: 5px;
// 	font-style		: normal;
// 	font-weight		: 400;
// 	letter-spacing	: .03125rem;
// 	line-height		: 1.4;
// }
// .popup-arrow {
//   color: rgb(255, 255, 255);
// }
// [role='tooltip'].popup-content {
//   width: 200px;
//   box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
// }

// .popup-overlay {
//   background: rgba(0, 0, 0, 0.5);
// }
// [data-popup='tooltip'].popup-overlay {
//   background: transparent;
// }

.popup-content {
  .menu {

    .menu-item {

      a {
        border: none;
        text-decoration: none;
        color: #000;
        font-size: 1.7rem;
        font-weight: 500;
        margin: 0.9rem 0.9rem 0.9rem 0;
        white-space: nowrap;

        border-bottom: 0.2rem solid transparent;
        transition: border-bottom 0.2s;
        padding-bottom: 0.3rem;

        &:hover, &:active {
          border-bottom: 0.2rem solid $color-secondary;
          padding-bottom: 0.3rem;
        }
      }
    }
  }

  .animal-images {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
  
    img {
      max-height: 100vh;
      margin: 0 auto;
    }
  
    .right {
      position: absolute;
      z-index: 100;
      top: 50%;
      transform: translateY(-50%);
    }

    .arrow {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      z-index: 100;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.8);
      width: 3.5rem;
      height: 3.5rem;
      text-align: center;
  
      @media (max-width: 480px) {
        background-color: auto;
      }
  
      button {
        border: none;
        background: none;
        font-size: 2.5rem;
        color: #fff;
      }
    }
  
    .left {
      left: 1rem;
    }
  
    .right {
      right: 1rem;
    }
  
  }
}