@import '../assets/stylesheets/style.scss';

.footer {
	width: 100%;
	min-height: 54rem;
	background: center url('../assets/images/footer_bg.png');
	padding: 0 7%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	.main {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		color: white;

		> div {
			margin-top: 4%;
		}

		h1 {
			font-size: 3.5rem;
			font-family: 'Raleway', sans-serif;
		}
		
		hr {
			background-color: $color-secondary;
			margin: .5rem 0 2rem;
			width: 4rem;
			height: 0.3rem;
			border: 0;
		}

		p {
			font-size: 1.8rem;
		}

		input {
			border: 0;
	
			&[type='text'] {
				background-color: rgba(255, 255, 255, 0.5);
				margin: 2rem 0;
				padding: 1rem;
				width: 30rem;
				color: #fff;
				font-size: 1.8rem;

				&::placeholder {
					color: $color-secondary;
				}
			}
		}

		.image-list {
			display: flex;
			align-items: center;

			img {
				margin-right: 0.75rem;
			}
		}
	}

	.bottom {
		bottom: 0;
		margin-top: 6rem;
    padding-right: 8rem;

		p {
			color: #fff;
			margin-bottom: 2rem;

			a {
				color: #fff;
				font-weight: 500;
			}
		}
	}
}
