@import '../assets/stylesheets/style.scss';

.header {
  width: 100%;
  height: 15rem;
	padding: 4rem 1rem 0 3rem;
	background-color: $color-primary;
	position: relative;

	@media (min-width: 700px) {
		&.home {
			background-color: #fff;

			.header__logo img {
				&.white {
					display: none;
				}
				&.color {
					display: block;
				}
			}
		}
	}

	&__logo {
		display: inline-block;
		padding-right: 1rem;

		img {
			height: 8.2rem;

			&.color {
				display: none;
			}
		}
	}

	&__nav {
		display: inline-block;
		list-style: none;

		@media (min-width: 700px) {
			&.home li {
				a, .popup-link {
					color: $color-primary;
				}
			}
		}

		li, &.home li {
			display: inline-block;
			margin-left: 4rem;
			
			&.lose-10 {
				@media (max-width: 1200px) {
					display: none;
				}
			}

			&.gain-10 {
				@media (min-width: 1200px) {
					display: none;
				}
			}

			&.lose-20 {
				@media (max-width: 1080px) {
					display: none;
				}
			}

			&.gain-20 {
				@media (min-width: 1080px) {
					display: none;
				}
			}

			&.gain-30 {
				@media (min-width: 960px) {
					display: none;
				}
			}

			&.lose-30 {
				@media (max-width: 960px) {
					display: none;
				}
			}

			&.gain-40 {
				@media (min-width: 800px) {
					display: none;
				}
			}

			&.lose-40 {
				@media (max-width: 800px) {
					display: none;
				}
			}

			&.gain-50 {
				@media (min-width: 700px) {
					display: none;
				}
			}

			.other {
				@media (min-width: 1200px) {
					display: none;
				}
			}
		}

		li {
			a, .popup-link {
        background: none;
        border: none;
				color: #fff;
				text-decoration: none;
				font-size: 1.7rem;
				font-weight: 700;
				border-bottom: 0.2rem solid transparent;
				transition: border-bottom 0.2s;
				padding-bottom: 0.3rem;
				white-space: nowrap;

				&:hover, &:active {
					border-bottom: 0.2rem solid $color-secondary;
					padding-bottom: 0.3rem;
				}
			}
		}

		ul li, &.home ul li {
			display: block;
			margin: 0 0 0.9rem 0;

			&:last-child {
				margin-bottom: 0;
			}

			a {
				color: #000;
				font-weight: 500;
			}
		}
	}

	.donate-cat {
		bottom: 1rem;
		right: 4rem;
		position: absolute;

		@media (max-width: 700px) {
			display: none;
		}

		a {
			z-index: 200;
			position: relative;
		}

		img {
			position: absolute;
			top: -71px;
			left: 52px;
			z-index: 100;
			max-height: 237px;
		}
	}


}




