@import '../../assets/stylesheets/style.scss';

.featured-pets {
  width: 100%;
  padding: 3rem 6% 2.6rem;

  h1 {
    color: $color-accent;
  }

  h2 {
    font-size: 3.3rem;
    margin-bottom: 1rem;

    @media (max-width: 500px) {
      font-size: 3.1rem;
    }
  }

  .animal-link {
    color: $color-secondary;
    text-decoration: none;
  }

  hr {
    background-color: $color-primary;
  }

  .pet-outer {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  .pet-info {
    flex: 1 0;
    margin-left: 1.5%;

    ul {
      list-style: none;
      margin-top: 0.5rem;

      li {
        display: flex;
        align-items: center;
        padding: 0.1rem 0 0.2rem 1rem;
        border-top: solid 1px $color-primary;

        &:last-child {
          border-bottom: solid 1px $color-primary;
        }

        label {
          flex: 0 1 15rem;
          font-size: 1.6rem;
          font-weight: 500;
        }

        span {
          flex: 1;
          font-size: 1.5rem;
          margin-left: 2rem;
        }
      }
    }
  }

  .pet-image {
    img {
      max-height: 24.5rem;
    }
  }

  .view-buttons {
    margin-top: 2rem;
    text-align: center;

    a {
      margin: 1rem;
    }
  }
}
