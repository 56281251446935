@import '../assets/stylesheets/style.scss';

.contact {
	h2 {
		font-size: 3.5rem;
		color: $color-primary;
		margin-top: 2rem;
	}
}

.form {
	background-image: url('../assets/images/catdoggreen.png');
	background-repeat: no-repeat;
	background-position: 13% 14rem;

	@media (max-width: 500px) {
		background-size: 25%;
	}

	@media (max-width: 360px) {
		background-image: none;
	}
}
