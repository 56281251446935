@import '../assets/stylesheets/style.scss';

.standard {
	font-family: 'Raleway', sans-serif;
	margin-top: 5%;
	padding: 0 4%;

	h1 {
		color: $color-accent;
		font-size: 4.5rem;
	}

	hr {
		background-color: $color-accent;
	}

	p, ::marker {
		font-size: 1.8rem;
		font-weight: 500;
	}
	
	p {
		margin-bottom: 3rem;
	}
}
