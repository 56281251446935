.home {
	width: 100%;
	font-family: 'Raleway', sans-serif;
	
  h1 {
    font-size: 3.5rem;
	}
	
	p {
		color: #fff;
		font-size: 1.8rem;
		font-weight: bold;
		line-height: 1.3;
	}
}
