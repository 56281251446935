@import '../assets/stylesheets/style.scss';

.form {
	background: $color-primary;
	padding: 1rem 1rem 2.2rem;

	display: grid;
	grid-template-columns: 1fr 1fr;
	align-items: center;
	gap: 1rem;

	.full-width, .submit {
		grid-column-start: span 2;
		margin-top: 1.8rem;
	}

	.submit {
		justify-self: center;
	}

	.full-width {
		justify-self: stretch;
	}

	> label {
		text-align: right;
		vertical-align: middle;
	}

	.input {
		display: flex;
		flex-wrap: wrap;
	}

	> input, .input, .input-select, textarea {
		flex: 1;
		padding: 0.5rem;
	}

	> input, .input-select {
		background-color: #fff;
	}
	
	> .input > input {
		vertical-align: middle;
	}
	
	.required-select {
		position: relative;
	}

	.required-input {
		position: absolute;
		opacity: 0;
		width: 100%;
		height: 0;
	}

	label {
		font-size: 2rem;

		@media (max-width: 600px) {
			font-size: 1.6rem;
		}
	}

	.input {
		label {
			margin-right: 2.5rem;
		}

		input[type='radio'] {
			margin-right: 0.5rem;
		}

		input[type='checkbox'] {
			margin-right: 0.7rem;
		}
	}
			
	textarea {
		height: 10rem;
  }
  
  h2, label, p, .phone {
    color: #fff;
  }

  h2 {
		font-size: 3.5rem;
  }

  h2, p {
    align-self: flex-start;
  }
}
