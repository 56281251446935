@import '../assets/stylesheets/style.scss';

.search-content {
  .search-form {
    gap: 0.6rem;
  }

  .search-select {
    padding: 0;
  }

  .search-input {
    border: 0;
  }

  .actions {
    .btn {
      margin: 0 1rem 1rem;
    }
  }
}
