
#root {
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: #f0f8ff;
}

.padded {
	padding: 1%;
}



.half, .sixty, .forty {
	overflow: hidden;
}

.sixty .content {
	margin-right: 1%; 
	margin-bottom: 1%;
	padding: 0 1% 1.4% 1%; 
	border: 1px solid black; 
	overflow: none;
}

.left {
    float: left;
}

.right {
	float: right;
}


/* IE doesn't support the @media tag, so define basic size rules here
first before separating out by screen size for responsive design */

.half {
	width: 50%;
}

.sixty {
	width: 60%;
}

.forty {
	width: 40%;
}

.third {
	width: 33%;
}

.fifteen {
	width: 15%;
}

.eighty-five {
	width: 85%;
}

.seventy-five {
	width: 75%;
}

.twenty-five {
	width: 25%;
}

.rcol {
	width:16%;
}

.lcol {
	width:80.68%;
}


.mobile {
	display: none;
}

.noTablet {
	display: none;
}


/* print styles */
@media print {
}
