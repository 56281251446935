// Colors
$color-primary: #1c75bb;
$color-primary-alt: #1576bc;
$color-secondary: #a0cd3a;
$color-secondary-alt: #b2e63b;
$color-accent: #fd8d5c;


// Grid
$grid-width: 120rem;
$gutter-vertical: 4rem;
$gutter-horizontal: 6rem;

$content-width: 1200px;

$border-width: 0.6rem;
$border-radius: 1.5rem;