@import '../assets/stylesheets/style.scss';

.App {
	width: 100%;
	max-width: $content-width;
	font-family		: 'Arimo', 'Roboto', 'CartoGothic Std', 'Lucida Grande', 'Lucida Sans Unicode',Verdana, sans-serif;
	font-size		: 13px;
	font-style		: normal;
	font-weight		: 400;
	letter-spacing	: .03125rem;
	line-height		: 1.4;
	display			: flex;
	flex-direction  : column;
	align-items: center;
	background-color: #fff;

	@media (min-width: $content-width) {
		width: $content-width;
	}
}

div.content {
	text-align: left;
	width: 100%;
}
