@import '../assets/stylesheets/style.scss';

.working-cats {
	display: flex;
	flex-direction: column;

	img {
		max-height: 45rem;
		align-self: center;
	}

	h2 {
		font-size: 3.5rem;
		color: $color-primary;
		margin: 3rem 0 2rem;
	}

	h3 {
		color: $color-accent;
		font-size: 1.8rem;
		font-weight: 700;
		margin-bottom: 0.8rem;
	}
}
