@import '../assets/stylesheets/style.scss';

.help-cause {
	font-family: 'Raleway', sans-serif;
	padding: 4% 12% 0 12%;

	.main {
		flex: 1 0;

		h1, hr {
			display: none;
		}

		@media (max-width: 700px) {
			h1, hr {
				display: block;
			}
	
			h1 {
				color: $color-accent;
				margin-top: 1rem;
				font-size: 2.5rem;
				font-weight: 500;
				line-height: 2.5rem;
			}
		
			hr {
				background-color: $color-accent;
			}
		}
	}

	.top {
		position: relative;

		img {
			width: 100%;
		}
		
		h1 {
			display: block;
			position: absolute;
			top: 1.5rem;
			left: 1.5rem;
			color: #3d0606;
			font-size: 3.5rem;

			@media (max-width: 700px) {
				display: none;
			}
		}

		p {
			position: absolute;
			bottom: 0.6rem;
			right: 0.6rem;
			color: #fff;
			width: 29rem;
			font-weight: 700;
			font-size: 1.4rem;

			@media (max-width: 700px) {
				display: none;
			}
		}
	}

	.make-donation {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-evenly;
		align-items: stretch;
		margin: 2.5rem 0;

		> div {
			position: relative;
			flex: 1;
			margin: 5.5rem 1rem 0;
			padding: 7rem 2rem 2.2rem;
			color: $color-secondary;
			border: $border-width solid $color-primary;
			border-radius: $border-radius;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			@media (max-width: 625px) {
				padding: 6rem 2rem 1.2rem;
			}

			&:before {
				content: '';
				background: url('../assets/images/kitty.png') no-repeat top/auto 11rem;
				width: 100%;
				position: absolute;
				height: 11rem;
				top: -5.5rem;

				@media (max-width: 625px) {
					height: 9rem;
					background-size: auto 9rem;
					top: -4.5rem;
				}
			}

			p {
				font-size: 3.5rem;
				font-weight: 700;
				line-height: 3.5rem;
				text-align: center;
				margin-bottom: 1.2rem;

				@media (max-width: 625px) {
					font-size: 2.2rem;
					line-height: 2.2rem;
				}
			}
		}
	}

	.mail-check {
		background-color: $color-secondary;
		padding: 2rem;

		p {
			color: #fff;
			font-size: 1.8rem;
			font-weight: 700;
			text-align: center;
		}
	}

	.other {
		background-image: url('../assets/images/largeBlueCat.png');
		padding: 2rem 6rem 3rem;
		
		h1 {
			font-size: 3.5rem;
			color: $color-accent;

			@media (max-width: 425px) {
				font-size: 2.5rem;
			}
		}

		hr {
			background-color: #fff;
		}

		.panels {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
		}

		.panel {
			background-color: white;
			border-radius: 1.5rem;
			margin-bottom: 5rem;
			padding: 2rem;
			width: 30%;
			min-height: 17rem;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: center;

			@media (max-width: 800px) {
				width: 45%;
			}

			@media (max-width: 625px) {
				width: 100%;
				min-height: 17rem;
			}

			> p {
				color: $color-primary;
				font-size: 2.9rem;
				font-weight: 700;
				text-align: center;
				margin-bottom: 1.2rem;

				@media (max-width: 625px) {
					font-size: 2.2rem;
				}
			}
		}
	}
	.popup-modal {
		h2 {
			width: 100%;
			border-bottom: 1px solid gray;
			font-size: 2.5rem;
			color: $color-primary;
			text-align: center;
			padding: 0.5rem;
		}

		.content {
			width: 100%;
			padding: 1rem 0.5rem;

			p {
				margin: 2rem 0;
				font-size: 1.8rem;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}

		.actions {
			width: 100%;
			display: flex;
			justify-content: center;

			.btn {
				margin: 0 1rem 1rem;
			}
		}

		.close {
			position: absolute;
			display: block;
			padding: 2px 5px;
			line-height: 20px;
			right: -10px;
			top: -10px;
			font-size: 24px;
			background: #ffffff;
			border-radius: 18px;
			border: 1px solid #cfcece;
		}
	}
}
