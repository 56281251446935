@import '../assets/stylesheets/style.scss';

.adoptable-list {
	h2 {
		font-size: 3.5rem;
		color: $color-primary;
		margin-bottom: 1rem;
	}

	> div {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-evenly;
		align-items: stretch;
  }
}
  
.search {
  float: right;
}

.pages {
	display: flex;
	justify-content: center;

	a {
		color: #000;
		font-size: 16px;
		margin: 0 5px;
		padding: 0 5px;
		text-decoration: none;

		&.current {
			border: solid 1px;
		}
	}
}