@import '../assets/stylesheets/style.scss';

.donate {
	display: flex;
	flex-direction: column;

	.btn {
		align-self: center;
	}

	form {
		display: flex;
		flex-direction: column;

		input, img {
			align-self: center;
		}

		&.subscribe {
			margin-bottom: 3rem;
		}

		.subscribe {
			width: 35rem;

			* {
				color: #000;
				font-size: 1.5rem;
				font-weight: 500;
			}
		}
	}

	.levels {
		font-weight: 700;
		margin-bottom: 1rem;
	}

	h2 {
		font-size: 3.5rem;
		color: $color-primary;
		margin: 3rem 0 2rem;
	}

	h3 {
		color: $color-accent;
		font-size: 1.8rem;
		font-weight: 700;
		margin-bottom: 0.8rem;
	}

	ul {
		margin: 0 0 2rem 2rem;
		font-size: 1.8rem;
		font-weight: 500;
	}

	.choices {
		display: flex;
		justify-content: center;
		align-items: center;

		> img {
			width: 15rem;
			margin-left: 10rem;
		}
	}


	@media screen AND (max-width: 600px) {

		h1 {
			font-size: x-large;
		}
	
	}
}

