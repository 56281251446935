@import '../assets/stylesheets/style.scss';

.popup-modal {
  h2 {
    width: 100%;
    border-bottom: 1px solid gray;
    font-size: 2.5rem;
    color: $color-primary;
    text-align: center;
    padding: 0.5rem;
  }

  .content {
    width: 100%;
    padding: 1rem 0.5rem;

    p {
      color: #000;
      margin: 2rem 0;
      font-size: 1.8rem;
      font-weight: 400;
      line-height: 1.4;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .actions {
    width: 100%;
    display: flex;
    justify-content: center;

    .btn {
      margin: 0 1rem 1rem;
    }
  }

  .close {
    position: absolute;
    display: block;
    padding: 2px 5px;
    line-height: 20px;
    right: -10px;
    top: -10px;
    font-size: 24px;
    background: #ffffff;
    border-radius: 18px;
    border: 1px solid #cfcece;
  }
}
