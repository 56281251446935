@import '../assets/stylesheets/style.scss';

.volunteer {
	h2 {
		font-size: 3.5rem;
		color: $color-primary;
		margin-top: 5rem;
	}

	img {
		min-width: 20rem;
		width: 40%;
		margin: 0 0 2.5% 2.5%;
	}

	h3 {
		font-size: 2.5rem;
		margin: 1rem 0 0.5rem;
	}
}
