@import '../assets/stylesheets/style.scss';

.sponsors {
  width: 100%;
	background-color: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 6%;

  @media (max-width: 1000px) {
    display: block;
  }

  .scroller {
    display: flex;
    justify-content: flex-start;
    width: 100%;

    > div {
      padding-top: 8rem;
      padding-bottom: 8rem;

      @media (max-width: 1000px) {
        padding-top: 3rem;
        padding-bottom: 3rem;
      }

      &.scroller-tiles {
        padding: 0;
        height: 23.7rem;
        width: 100%;
        margin: 0 6rem 0;

        @media (max-width: 1000px) {
          height: 13.7rem;
        }

        > div {
          width: 18rem;
          height: 100%;
          font-size: 1rem;
          display: flex;
          flex-direction: column;
          justify-content: center;

          img {
            width: 100%;
          }
        }
      }
    }
  }

  h1, .scroller-arrow button {
    font-size: 3.5rem;
    color: $color-primary;
    margin-right: 6rem;
    white-space: nowrap;
  }

  hr {
    background-color: #fd8d5c;
  }

  .scroller-arrow button {
    margin-right: 0;
    color: #000;
    font-weight: bolder;
  }
}
