div#success-stories ul li {
	display: inline;

	img.success_thumb {
		border: 5px solid transparent;
		margin-bottom: 10px;
		height: 100px;
	}
	
}
